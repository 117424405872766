<template>
    <div id='copy'>
        <el-row :gutter="20">
            <el-col :span="24">
              <div class="content">
                  <h4>店铺同步</h4>
                  <p class="tips">
                      <span>*</span>提示：本功能可以将某个店铺的产品搬家到您的店铺，请确保该店铺已经授权本系统，并获得同步编号。本功能仅限正式会员使用。
                  </p>
              </div>
            </el-col>

             <el-col :span="$store.state.isMobile ? 24 : 12">
              <div class="content">
                  <h6>本店同步编号</h6>
                  <h2>{{mykeys}}</h2>
              </div>
            </el-col>

              <el-col :span="$store.state.isMobile ? 24 : 12">
              <div class="content">
                   <h6>一键同步</h6>
                   <div class="input">
                       <el-input
                        placeholder="请输入店铺同步编号"
                        v-model="userkey"
                        value='number'
                        clearable>
                        </el-input>


                        <el-button v-if='$store.state.userinfo' :disabled='$store.state.userinfo.type=="试用"||btnStatus==false' @click="copyshop" class="btn" type="primary" :icon='btnStatus?"el-icon-document-copy":"el-icon-loading"'>同步</el-button>
                   </div>
              </div>
            </el-col>

        </el-row>


    </div>
</template>
<script>
export default {
    name:'copy',
    data(){
        return{
            userkey:'',
            mykeys:'',
            btnStatus:true,
        }
    },
    methods: {
        mykey(){
            this.axios.get(this.api.mykey)
            .then((res)=>{
                if(res.code==1){
                  this.mykeys=res.key
                }
            })
        },
        copyshop(){
            if(this.userkey!=''){
                this.btnStatus=false;
                this.axios.get(this.api.copy,{
                    params:{
                        key:this.userkey
                    }
                })
                .then((res)=>{
                    this.btnStatus=true;
                    if(res.code==0){
                         this.$message({
                            message: '已成功同步'+res.count+'个产品',
                            type: 'success'
                        });
                    }else{
                        this.$message({
                            message: res.msg,
                            type: 'warning'
                        });
                    }
                   
                })
            }
        }
    },
    created() {
        if(this.$store.state.userinfo){
             if(this.$store.state.userinfo.type!='试用'){
                this.mykey();
            }else{
                this.mykeys='*******';
                 this.$message({
                    message: '如需使用本功能，需开通会员',
                    type: 'warning'
                });
            }
        }
    },
    watch:{
        "$store.state.userinfo":function(state){
             if(state.type!='试用'){
                this.mykey();
            }else{
                this.mykeys='*******';
                 this.$message({
                    message: '如需使用本功能，需开通会员',
                    type: 'warning'
                });
            }
        }
    }
}
</script>